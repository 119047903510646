import { Breadcrumb, Card } from 'antd';
import React from 'react';
import { Helmet } from 'react-helmet';

const About: React.FC = () => {
    return (
        <>
            <Helmet>
                <title>
                    Cinverse Website - About
                </title>
            </Helmet>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>About</Breadcrumb.Item>
            </Breadcrumb>
            <Card>
                Ini Pages Untuk About
            </Card>
        </>
    );
};

export default About;

import { Breadcrumb, Card } from 'antd';
import React from 'react';
import { Helmet } from 'react-helmet';

const Registration: React.FC = () => {
    return (
        <>
            <Helmet>
                <title>
                    Cinverse Website - Registration
                </title>
            </Helmet>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Registration</Breadcrumb.Item>
            </Breadcrumb>
            <Card>
                Ini Pages Untuk Registrasi
            </Card>
        </>
    );
};

export default Registration;

import { Breadcrumb, Card } from 'antd';
import React from 'react';
import { Helmet } from 'react-helmet';

const Ingredients: React.FC = () => {
    return (
        <>
            <Helmet>
                <title>
                    Cinverse Website - Tools Ingredients
                </title>
            </Helmet>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Tools</Breadcrumb.Item>
                <Breadcrumb.Item>Ingredients Calc</Breadcrumb.Item>
            </Breadcrumb>
            <Card>
                Ini Pages Untuk Tools Ingredients
            </Card>
        </>
    );
};

export default Ingredients;

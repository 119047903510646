import {
    DesktopOutlined,
    FileOutlined,
    PieChartOutlined,
    UserOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    label: React.ReactNode,
    key: React.Key,
    scope?: string[],
    icon?: React.ReactNode,
    children?: MenuItem[],
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        scope,
    } as MenuItem;
}

export const SIDE_MENU = [
    getItem('Home', '/', [''], <PieChartOutlined />),
    getItem('Registration', 'pages/registration', [''], <DesktopOutlined />),
    getItem('Tools', 'pages/tools', [''], <UserOutlined />,
        [getItem('Nether Calc', 'pages/tools/nethercalc', ['']),
        getItem('Ingredients Calc', 'pages/tools/ingredients', [''])]),
    getItem('About', 'pages/about', [''], <FileOutlined />),
]
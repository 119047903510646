import { Card, Space } from 'antd';
import { Helmet } from 'react-helmet';

function ViewDasboard() {
    return (
        <>
            <Helmet>
                <title>
                    Cinverse Website - Home
                </title>
            </Helmet>
            {/* <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
            </Breadcrumb> */}
            <Space direction='vertical' size={0} style={{ display: 'flex' }}>
                <Card
                    style={{
                        backgroundImage: "url('/images/minecraft-bg.png')",
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundBlendMode: 'overlay',
                        backgroundColor: 'rgba(0, 0, 0, 0.6)',
                        borderRadius: 0,
                    }}
                    size='default'
                    bordered={false}
                >
                    <div style={{
                        textAlign: 'center',
                        color: 'white',
                    }}>
                        <h1 style={{
                            fontSize: '35px',
                            margin: 0,
                            padding: 0,
                            lineHeight: '1.2',
                        }}>Cinverse Website</h1>
                        <h2 style={{
                            fontSize: '16px',
                            margin: 0,
                            padding: 0,
                            lineHeight: '1',
                        }}>
                            Community Minecraft From Indonesia
                        </h2>
                        <p style={{
                            marginTop: '25px',
                            fontSize: '14px',
                            lineHeight: '1.3',
                        }}>
                            Selamat datang di website cinverse, dimana kamu bisa menemukan hal menarik di server ini.
                            Kamu juga dapat bergabung di server ini sebagai player loh. Yuk join sekarang!
                        </p>
                    </div>
                </Card >
                <Card style={{
                    backgroundColor: 'white',
                    minHeight: '20vh',
                    borderRadius: 0,
                }}
                    title={<p style={{ fontSize: '20px', margin: 0, padding: 0, textAlign: 'center' }}>Feature</p>}
                    size='small'
                    bordered={false}
                >
                    <Space direction='vertical' size={'small'} style={{
                        width: '100%'
                    }}>
                        <Card title='Collaboration'
                            size='small'
                            style={{
                                textAlign: 'left'
                            }}>
                            test
                        </Card>
                        <Card title='Ability'
                            size='small'
                            style={{
                                textAlign: 'left'
                            }}>
                            test
                        </Card>
                    </Space>
                </Card>
                <Card style={{
                    textAlign: 'center',
                    backgroundColor: 'rgba(236, 236, 236, 1)',
                    minHeight: '20vh',
                    borderRadius: 0,
                }}
                    size='small'
                    bordered={false}
                >
                    <p>
                        Something Awesome...
                    </p>
                </Card>
                <Card style={{
                    textAlign: 'center',
                    backgroundColor: 'rgba(236, 236, 236, 1)',
                    minHeight: '20vh',
                    borderRadius: 0,
                }}
                    size='small'
                    bordered={false}
                >
                    <p>
                        Something Awesome...
                    </p>
                </Card>
                <Card style={{
                    textAlign: 'center',
                    backgroundColor: 'rgba(236, 236, 236, 1)',
                    minHeight: '20vh',
                    borderRadius: 0,
                }}
                    size='small'
                    bordered={false}
                >
                    <p>
                        Something Awesome...
                    </p>
                </Card>
            </Space>

        </>
    );
};

export { ViewDasboard }